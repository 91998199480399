/************************************************************************************
FOOTER
*************************************************************************************/

.footer {
  font-size: 15px;
  line-height: 1.8em;
  background: #DBD7D7;
  @include rem(padding, 30px 0 0 0);

  .head {
    @include rem(font-size, 24px);
    line-height: 1.3em;
    @include rem(margin-bottom, 30px);
  }

  .contact-list {
    @include rem(font-size, 18px);

    ul {
      li {
        padding: 0 5px 0 0;
        display: flex;

        .label {
          width: 20px;
          flex: 0 0 20px;
          display: inline-block;
        }

        &:before {
          content: none;
        }
      }
    }

    a {
      color: $red;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  ul {
    li {
      @include rem(padding-left, 30px);
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 5px;
        width: 16px;
        height: 16px;
        background: url('../img/li.svg') center center no-repeat;
        background-size: contain !important;
      }
    }

    &.dl-list {
      li {
        &:before {
          background-image: url('../img/li-dl.svg');
        }
      }
    }

    &.unstyled {
      li {
        padding-left: 0;

        &:before {
          content: none;
        }
      }
    }
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .mw {
    position: relative;
    color: #c6c6c6;
    display: block;

    &:hover {
      color: #00daee;
    }

    .text {
      position: absolute;
      left: 0;
      top: 0;
      text-indent: -80000px;
      display: block;
    }

    .icon-mw {
      width: 70px;
      height: 30px;
    }
  }
}

.foot-item {
  @include rem(padding, 20px 0);
}