/************************************************************************************
MEDIA QUERIES
*************************************************************************************/

/*========================= Viewport width 1400px and lower =========================*/

@media screen and (max-width: 1400px) {
  .glide-nav {
    .glide__arrow--left {
      left: 0;
      @include rem(margin-top, -25px);
      @include rem(width, 50px);
      @include rem(height, 50px);
      border-radius: 0 5px 5px 0;

      .icon {
        @include rem(width, 20px);
        @include rem(height, 20px);
      }
    }

    .glide__arrow--right {
      right: 0;
      @include rem(margin-top, -25px);
      @include rem(width, 50px);
      @include rem(height, 50px);
      border-radius: 5px 0 0 5px;

      .icon {
        @include rem(width, 20px);
        @include rem(height, 20px);
      }
    }
  }
}

/*========================= Viewport width 1199px and lower =========================*/

@media screen and (max-width: 1199px) {
  h1, .h1 {
    @include rem(font-size, 45px);
  }

  h2, .h2 {
    @include rem(font-size, 40px);
  }

  .btn {
    @include rem(font-size, 18px);
    @include rem(height, 60px);
  }

  form.std {
    input.text, select {
      @include rem(height, 60px);
    }
  }

  .main-nav {
    ul {
      li {
        a {
          @include rem(font-size, 16px);
        }
      }
    }
  }
}

/*========================= Viewport width 991px and lower =========================*/

@media screen and (max-width: 991px) {
  html {
    font-size: 15px;
  }

  h2, .h2 {
    @include rem(font-size, 45px);
  }

  .main-nav {
    display: none;
  }

  .nav-btn {
    display: block;
  }

  .mobile-nav-wrap {
    display: block;
  }

  .btn {
    @include rem(font-size, 20px);
    @include rem(height, 60px);
  }

  .clubs {
    .item {
      max-width: 33.33333%;
      flex: 0 0 33.33333%;
    }
  }

  .video {
    .play {
      @include rem(width, 60px);
      @include rem(height, 60px);
      @include rem(margin-left, -30px);
      @include rem(margin-top, -30px);

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0 10px 16px;
        border-color: transparent transparent transparent $red;
        @include translate(-50%,-50%);
      }
    }
  }
}

/*========================= Viewport width 767px and lower =========================*/

@media screen and (max-width: 767px) {
  html {
    font-size: 14px;
  }

  .boxes {
    .item {
      .num {
        @include rem(font-size, 40px);
      }
    }
  }

  .stacktable.small-only {
    display: table;
  }

  .stacktable.large-only {
    display: none;
  }

  .side-box-news {
    display: none;
  }
}

/*========================= Viewport width 575px and lower =========================*/

@media screen and (max-width: 575px) {
  html {
    font-size: 13px;
  }

  h1 {
    @include rem(font-size, 40px);
  }

  h2, .h2 {
    @include rem(font-size, 35px);
  }

  .clubs {
    .main {
      padding: 10px;
    }

    .item-list {
      margin: 0 -5px;
    }

    .item {
      padding: 0 5px;
      margin: 5px 0;
      max-width: 50%;
      flex: 0 0 50%;
      &.col-auto {
        padding-left: 5px;
        padding-right: 5px;
      }
      .img, img {
        height: 80px;
        width: auto;
      }
    }
  }

  .teaser-alt {
    .teaser-content {
      @include rem(padding-top, 140px);
    }
  }

  .sports {
    .item {
      .text {
        @include rem(font-size, 25px);
      }
    }
  }

  .tabs {
    .tab-content {
      .tab {
        @include rem(padding-top, 30px);
      }
    }
  }
}