/************************************************************************************
HEADER
*************************************************************************************/

.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 130;

  .inner {
    @include rem(height, 105px);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    @include rem(max-width, 222px);
  }
}

.main-nav {
  vertical-align: top;
  text-align: left;

  ul {
    display: flex;
    align-items: center;
    @include rem(margin, 0 -10px);

    li {
      @include rem(padding, 0 10px);
      margin: 0;

      a {
        @include rem(font-size, 18px);
        font-weight: 600;
        color: #fff;
        text-decoration: none;
        position: relative;
        @include transition(all, .2);

        &:after {
          content: '';
          width: 0;
          height: 2px;
          background: #fff;
          position: absolute;
          left: 0;
          bottom: -10px;
          //display: none;
        }

        &:hover:after {
          width: 100%;
          @include transition(all, .2);
        }
      }
    }
  }
}

.nav-btn {
  display: none;
  vertical-align: top;
  width: 30px;
  height: 30px;
  text-align: center;
  position: relative;
  z-index: 2001;
  cursor: pointer;
  align-items: center;

  .lines {
    width: 30px;
    height: 4px;
    flex: 0 0 30px;
    display: block;
    position: relative;
    position: absolute;
    top: 50%;
    margin-top: -2px;
  }

  .line-1,
  .line-2,
  .line-3 {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    transition: 0.1s;
    position: absolute;
    left: 0;
    content: '';
    transform-origin: 0.28571rem center;
  }

  .line-1 {
    top: 0;
    transition: opacity 0.1s 0.1s ease;
  }

  .line-2 {
    top: 10px;
    transform-origin: 50% 50%;
    transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
    transition: top 0.1s 0.1s ease, transform 0.1s ease;
  }

  .line-3 {
    top: -10px;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
    transition: top 0.1s 0.1s ease, transform 0.1s ease;
  }

  &.active {
    .line-1 {
      transition: opacity 0.1s 0s ease;
      background: transparent;
      opacity: 0;
    }

    .line-2 {
      transform: rotate3d(0, 0, 1, 45deg);
      transition: top 0.1s ease, transform 0.1s 0.1s ease;
      top: 0;
    }

    .line-3 {
      transform: rotate3d(0, 0, 1, -45deg);
      transition: top 0.1s ease, transform 0.1s 0.1s ease;
      top: 0;
    }

    &:hover {
      .line-1,
      .line-2,
      .line-3 {
        background: #fff;
      }
    }
  }
}

.mobile-nav {
  background: $red;
  width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 90;
  overflow-y: auto;
  max-height: 100%;
  margin-top: -10px;
  @include rem(padding, 130px 0 30px 0);
  @include transition(all, .3);
  @include translate(0,-100%);

  &.active {
    @include translate(0,0);
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      color: #fff;
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      border-bottom: 1px solid rgba(255,255,255,0.1);

      &.active {
        a {
          color: #ffa70e;
        }
      }

      &:last-child {
        border: none;
      }

      a {
        @include rem(font-size, 17px);
        font-weight: 400;
        display: block;
        width: 100%;
        padding: 14px 0;
        color: #fff;
        border: none;
        position: relative;
        text-decoration: none;
      }
    }
  }
}

.mobile-nav-overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 80;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.9);
  display: none;
}

.mobile-nav-wrap {
  display: none;
}

.teaser-hp {
  background: $red url('../img/teaser.png') center 0 no-repeat;
  background-size: cover;

  .teaser-content {
    @include rem(padding, 230px 0 130px 0);
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .claim {
    @include rem(margin-bottom, 130px);
  }
}

.teaser-alt {
  background: $red url('../img/teaser.png') center bottom no-repeat;
  background-size: cover;
  color: #fff;

  .teaser-content {
    @include rem(padding, 200px 0 0 0);
    position: relative;
    z-index: 20;
  }

  .col-text {
    @include rem(padding-bottom, 80px);

    .inner {
      @include rem(max-width, 480px);
    }
  }

  .col-info {
    color: #000;
  }

  .btn {
    @include rem(margin-top, 20px);
  }

  .box {
    color: #000;
  }

  a {
    color: #fff;
  }
}

body.header-fixed {
  .header {
    .inner {
      @include rem(height, 80px);
    }
  }
}

body.mobile-nav-opened {
  .mobile-nav {    
    @include translate(0,0);
    margin-top: 0;
  }

  .nav-btn {
    .line-1 {
      transition: opacity 0.1s 0s ease;
      background: transparent;
      opacity: 0;
    }

    .line-2 {
      transform: rotate3d(0, 0, 1, 45deg);
      transition: top 0.1s ease, transform 0.1s 0.1s ease;
      top: 0;
    }

    .line-3 {
      transform: rotate3d(0, 0, 1, -45deg);
      transition: top 0.1s ease, transform 0.1s 0.1s ease;
      top: 0;
    }

    &:hover {
      .line-1,
      .line-2,
      .line-3 {
        background: #fff;
      }
    }
  }

  .mobile-nav-overlay {
    display: block;
    animation: showOverlay .4s;
  }
}

html.i-amphtml-fie>body.mobile-nav-opened,
html.i-amphtml-singledoc>body.mobile-nav-opened {
  overflow: hidden !important;
}

@keyframes showOverlay {
  0% {
    display: none;
    background: rgba(0,0,0,0);
  }
  5% {
    display: block;
    background: rgba(0,0,0,0);
  }
  100% {
    display: block;
    background: rgba(0,0,0,0.9);
  }
}