/************************************************************************************
RESET
*************************************************************************************/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, figure, footer, header, hgroup, menu, nav, section, menu,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

ol, ul { list-style: none; }

blockquote, q { quotes: none; }
blockquote:before, blockquote:after,
q:before, q:after { content: ''; content: none; }

:focus { outline: 0; }

table { border-collapse: collapse; border-spacing: 0; }

article, aside, figure, footer, header, hgroup, nav, section, img { display: block; }

input[type="radio"] { vertical-align: text-bottom; }
input[type="checkbox"] { vertical-align: bottom; *vertical-align: baseline; }

label, input[type=button], input[type=submit], button { cursor: pointer; overflow: visible; }

em, i { font-weight: inherit; }

/************************************************************************************
GENERAL
*************************************************************************************/

body {
	font-family: Sora,sans-serif;
	@include rem(font-size, 17px);
	line-height: 1.6em;
	font-weight: 400;
	color: #000;
	background: #fff url('../img/bg.png') center center no-repeat;
	background-attachment: fixed;
	background-size: cover;
	margin: 0;
	padding: 0;
	min-height: 100%;

	&.modal-opened {
		overflow: hidden;
	}
}

/* Box sizing */

*, *:after, *:before {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

/* Anchors */

a {
	color: #000;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

/* Heading */

h1, .h1 {
	font-family: Sora,sans-serif;
	@include rem(font-size, 55px);
	line-height: 1.3em;
	font-weight: 600;
	color: #fff;
	@include rem(margin, 0 0 30px 0);
}

h2, .h2 {
	font-family: Sora,sans-serif;
	@include rem(font-size, 40px);
	line-height: 1.3em;
	font-weight: 600;
	color: #000;
	@include rem(margin, 0 0 20px 0);
}

h3, .h3 {
	font-family: Sora,sans-serif;
	font-size: 25px;
	line-height: 1.4em;
	font-weight: 600;
	color: #000;
	@include rem(margin, 0 0 20px 0);

	&.small {
		font-size: 18px;
		line-height: 1.3em;
	}
}

h4, .h4 {
	font-family: Sora,sans-serif;
	@include rem(font-size, 25px);
	line-height: 1.3em;
	font-weight: 400;
	color: #000;
	@include rem(margin, 0 0 10px 0);
}

h5, .h5 {
	font-family: Sora,sans-serif;
	@include rem(font-size, 20px);
	line-height: 1.3em;
	font-weight: 400;
	color: #000;
	@include rem(margin, 0 0 10px 0);
}

h6, .h6 {
	font-family: Sora,sans-serif;
	@include rem(font-size, 20px);
	line-height: 1.3em;
	font-weight: 400;
	color: #000;
	@include rem(margin, 0 0 10px 0);
}

.suphead {
	@include rem(font-size, 17px);
	color: #8995a6;
	text-transform: uppercase;
	letter-spacing: 0.02em;
}

/* Paragraphs */

p {
	@include rem(margin, 0 0 20px 0);
}

address, cite {
	font-style: normal;
}

/* Lists */

ul {
	list-style: none;
	@include rem(margin, 0 0 20px 0);
	padding: 0;

	li {
		@include rem(padding, 0 0 0 20px);
		@include rem(margin, 0 0 5px 0);
	}

	&.unstyled li {
		padding: 0;
		background: none;
	}
}

ol {
	list-style: none;
	@include rem(margin, 0 0 20px 0);
	padding: 0;
	counter-reset: counter;
	margin-left: 0;

	li {
		@include rem(margin, 0 0 5px 0);
		@include rem(padding, 0 0 0 30px);
		counter-increment: counter;
		position: relative;

		&:before {
			color: $red;
			content: counter(counter) ". ";
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}

dl {
	@include rem(margin, 0 0 20px 0);
}

dt {
	font-weight: bold;
}

/* Various */

sup, sub {
	font-size: 60%;
	line-height: 0;
	height: 0;
	line-height: 1;
	vertical-align: baseline;
	_vertical-align: bottom;
	position: relative;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

img {
	max-width: 100%;
	height: auto;
}

.bold, b, strong {
	font-weight: 600;
}

.italic, i, em {
	font-style: italic;
}

.nowrap, .nobr {
	white-space: nowrap !important;
}

/************************************************************************************
CONTENT
*************************************************************************************/

.content {
	padding: 0;
}

/* Block */

.block {
	@include rem(padding, 80px 0);
	position: relative;

	&.narrow {
		@include rem(padding, 40px 0);
	}

	&.alt {
		background: #000;
	}

	&.alpha {
		padding-top: 0;
	}

	&.omega {
		padding-bottom: 0;
	}
}

.spacer {
	@include rem(height, 100px);

	&.narrow {
		@include rem(height, 40px);
	}
}

.anchor {
	position: absolute;
	left: 0;
	@include rem(top, 0);
}

.spacer {
	@include rem(height, 80px);

	&.huge {
		@include rem(height, 160px);
	}

	&.big {
		@include rem(height, 100px);
	}

	&.medium {
		@include rem(height, 60px);
	}

	&.small {
		@include rem(height, 40px);
	}
}

/* Various */

.section-head {
	@include rem(margin-bottom, 40px);
	text-align: center;

	h1, h2, h3 {
		margin-bottom: 0;
	}
}

.section-head-l {
	@include rem(margin-bottom, 40px);
	text-align: left;

	h1, h2, h3 {
		margin-bottom: 0;
	}
}

.intro {
	max-width: 500px;
	@include rem(margin, 0 auto 40px auto);
	text-align: center;

	&.wide {
		@include rem(max-width, 750px);
	}
}

/* Pager */

.pager {
	@include rem(margin, 30px 0);
	text-align: center;
	position: relative;

	ul {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 -5px;
	}

	li {
		padding: 0 5px;
		background: none;

		a {
			font-weight: 600;
			display: flex;
			align-items: center;
			justify-content: center;
			@include rem(width, 36px);
			@include rem(height, 36px);
			background: #fff;
			color: $red;
			text-align: center;
			text-decoration: none;
			@include transition(all, .5);
			box-shadow: 0 0 20px 0 rgba(0,0,0,.3);

			&:hover {
				background: $red;
				color: #fff;
			}
		}

		&.active {
			a {
				background: $red;
				color: #fff;
				text-decoration: none;
			}
		}
	}

	.prev {
		@include rem(width, 36px);
		@include rem(height, 36px);
		background: #000;
	}

	.next {
		@include rem(width, 36px);
		@include rem(height, 36px);
		background: #000;
	}

	.counter {
		display: none;
	}
}

/* Gmap */

.gmap-wrap {
	position: relative;

	.gmap {
		height: 600px;
		background: #e3e3e3;
	}

	img {
		max-width: inherit !important;
	}
}

.gmap {
	height: 480px;
	background: #e3e3e3;
}

.popup {
	font-size: 15px;
	@include rem(padding, 30px);

	.head {
		@include rem(font-size, 21px);
		font-weight: 600;
		color: #000;
		@include rem(margin-bottom, 10px);
	}

	ul {
		margin: 0 0 10px 0;

		li {
			margin-bottom: 0;
		}
	}

	.more {
		position: relative;
		@include rem(padding-left, 25px);

		&:before {
      content: '';
      position: absolute;
      left: 0;
      @include rem(top, 1px);
      @include rem(width, 16px);
      @include rem(height, 16px);
      background: url('../img/li.svg') center center no-repeat;
      background-size: contain !important;
    }
	}

	a {
		color: $red;
	}
}

.gm-style {
	.gm-style-iw-c {
		padding: 0;
	}
}

/*================================= Various Content =================================*/

.last {
	margin-bottom: 0 !important;
}

/* Buttons */

.btn {
	@include rem(font-size, 20px);
	font-family: Sora,sans-serif;
	line-height: 1.3em;
	font-weight: 700;
	display: inline-flex;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	background: $red;
	color: #fff;
	@include rem(padding, 0 40px);
	@include rem(height, 60px);
	text-decoration: none;
	position: relative;
	border: 2px solid $red;
	@include border-radius(5px);
	@include transition(all, .5);

	.icon-arr-r {
		max-width: 0;
		@include rem(width, 0);
		@include rem(height, 37px);
		@include rem(flex, 0 0 0);
		@include rem(max-width, 0);
		@include transition(all, .5);

		&:first-child {
			margin-right: 10px;
			margin-left: -10px;
		}
	}

	&:hover {
		background-color: #000;
		border: 2px solid #000;

		.icon-arr-r {
			display: block;
			@include rem(width, 37px);
			@include rem(max-width, 37px);
			@include rem(flex, 0 0 37px);
		}
	}

	&.small {
		@include rem(font-size, 18px);
		@include rem(height, 37px);
		@include rem(padding-left, 30px);
		@include rem(padding-right, 30px);

		.icon-arr-r {
			@include rem(width, 20px);
			@include rem(height, 20px);
		}

		&:hover {
			.icon-arr-r {
				@include rem(max-width, 20px);
				@include rem(width, 20px);
				@include rem(flex, 0 0 20px);
			}
		}
	}

	&.line {
		background: none;
		color: #0077bb;

		&:hover {
			background: #0077bb;
			border-color: #0077bb;
			color: #fff;
		}
	}

	&.alt {
		background: #fff;
		border: 2px solid #fff;
		color: $red;

  	&:hover {
			//background: $red;
			//border: 2px solid $red;
			//color: #fff;
  	}

  	&.line {
			background: none;
			color: #000;

			&:hover {
				background: #000;
				border-color: #000;
				color: #fff;
			}
		}
	}

	&.arr-r {
		&:after {
			content: '';
			border: solid #fff;
			border-width: 0 2.5px 2.5px 0;
		  display: inline-block;
		  padding: 3px;
		  margin-left: 5px;
		  @include rotate(-45deg);
		}
	}
}

/* SVG icons */

.icon {
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: top;
	position: relative;
	fill: currentColor;
}

/* Cookie Notice */

.cookie-notice {
	font-size: 14px;
	position: fixed;
	z-index: 10000;
	bottom: 0;
	width: 100%;
	padding: 10px 15px;
	background: rgba(0,0,0,0.90);
	color: #CCC;
	text-align: center;
}

.cookie-button {
	display: inline-block;
	padding: 2px 10px;
	margin-left: 25px;
	background: #02ad65;
	color: #fff;
	text-align: center;

	&:hover {
		background: #90ca16;
		color: #fff;
	}
}

.cookie-test {
	position: fixed;
	z-index: 10000;
	bottom: 0;
	width: 150px;
	padding: 25px 15px;
	background: rgba(50,50,50,0.95);
	color: #CCC;
	text-align: center
}

/* FY Apple! */

textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
.btn {
	-webkit-appearance: none;
}

/************************************************************************************
ENTRY
*************************************************************************************/

.entry {
	@include rem(margin, 0 0 40px 0);

	h2, h3 {
		@include rem(margin-top, 30px);

		&::first-child {
			margin-top: 0;
		}
	}

	ul {
		li {
			@include rem(padding-left, 30px);
      position: relative;

			&:before {
        content: '';
        position: absolute;
        left: 0;
        top: 5px;
        width: 16px;
        height: 16px;
        background: url('../img/li.svg') center center no-repeat;
        background-size: contain !important;
      }
		}

		&.unstyled {
			li {
				padding: 0;

				&:before {
					content: none;
				}
			}
		}
	}
}

/************************************************************************************
TABLES
*************************************************************************************/

.entry {
	table {
		font-size: 15px;
		line-height: 1.5em;
		width: 100%;
		@include rem(margin, 0 0 30px 0);

		th {
			font-weight: normal;
			border-bottom: 1px solid $red;
			@include rem(padding, 10px 15px);
			background: $red;
			color: #fff;
			text-align: left;
		}

		td {
			border: 1px solid #B1B1B1;
			@include rem(padding, 10px 15px);
		}
	}
}

.stacktable.large-only {
  display: table;
}

.stacktable.small-only {
  display: none;
}

/************************************************************************************
FORMS
*************************************************************************************/

form.std {
	label {
		@include rem(font-size,17px);
		line-height: 1.3em;
		color: #9f9f9f;
		@include rem(margin, 0 0 10px 0);
		display: block;
	}

	input.text {
		@include rem(font-size,17px);
		font-family: Sora,sans-serif;
		line-height: 1.3em;
		color: #000;
		border: none;
		@include rem(padding, 5px 20px);
		@include rem(height, 60px);
		border-radius: 5px;
		width: 100%;

		&.error {
			background: #fcf4f4;
			border-color: #be1e19;
		}

		&.req {
			background-image: url('../img/req.svg');
			background-position: right center;
			background-repeat: no-repeat;
		}
	}

	textarea {
		@include rem(font-size,17px);
		font-family: Sora,sans-serif;
		line-height: 1.3em;
		color: #000;
		resize: vertical;
		border: none;
		@include rem(padding, 15px 20px);
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		height: 150px;
		min-height: 150px;
		max-height: 300px;
		border-radius: 5px;
		display: block;

		&.req {
			background-image: url('../img/req.svg');
			background-position: right center;
			background-repeat: no-repeat;
		}
	}

	select {
		@include rem(font-size,17px);
		font-family: Sora,sans-serif;
		line-height: 1.3em;
		color: #000;
		width: 100%;
		@include rem(padding, 2px 20px);
		@include rem(height, 60px);
		border-radius: 5px;
		border: none;
		background: #fff url(../img/caret.svg) right center no-repeat;
		-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
	}

	option {
		@include rem(font-size,17px);
		line-height: 1.3em;
		@include rem(padding, 2px 10px);
	}

	input.text[required="required"] {
		//background-image: url('../img/req.png');
		//background-position: right center;
		//background-repeat: no-repeat;
	}

	.cell {
		@include rem(margin-bottom, 20px);
		position: relative;
		text-align: left;
	}

	.toggle {
		position: relative;
		@include rem(padding, 0 0 0 25px);

		label {
			display: inline-block;
			vertical-align: top;
			margin: 0;
		}

		input {
			margin: 0;
			position: absolute;
			left: 0;
			@include rem(top, 2px);
		}
	}

	.radio, .checker {
		position: absolute;
		left: 0;
		top: 0;
	}

	.checker {
    position: absolute;
    left: 0;
    top: 0;
    background: url('../img/checker.svg') 0 0 no-repeat;
    background-color: #fff;
    @include rem(background-position, 0 -23px);
    @include rem(background-size, 23px);
    @include transition(all, .5);
    border: 2px solid #fff;
    @include rem(width, 26px);
    @include rem(height, 26px);
    @include rem(margin, 0);
    border-radius: 50%;
    cursor: pointer;

    input {
    	opacity: 0;
    	position: absolute;
    	left: 0;
    	top: 0;
    	width: 100%;
    	height: 100%;
    	margin: 0;
    	cursor: pointer;
    }

    &.active {
      @include rem(background-position, 0 0);
      background-color: transparent;
    }

    &.has-error {
      border-color: #be1e19;
    }
  }
}

#form-ajax-content {
	position: relative;

	&.loading:after {
		content: '';
		position: absolute;
		z-index: 90;
		left: 50%;
		top: 50%;
		width: 70px;
		height: 70px;
		background: url('../img/spinner.svg') center center no-repeat;
		background-size: cover !important;
		@include translate(-50%,-50%);
	}

	& > .inner {
		opacity: 1;
		@include transition(all,.3);
	}

	&.loading > .inner {
		opacity: 0.4;
	}
}

::-webkit-input-placeholder { /* Edge */
  color: #000;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #000;
}

::placeholder {
  color: #000;
}

/*================================= Contact form 7 =================================*/

/* Mesages & errors */

body {
	.wpcf7 {
		div.wpcf7-response-output {
			@include rem(font-size, 16px);
			line-height: 1.3em;
			padding: 0;
			background: #ebf5bc;
			border: 1px solid #f1c7d2;
			color: #46b450;
			@include rem(padding, 15px 20px);
			@include rem(margin, 0 0 30px 0);
		}

		form.invalid,
		form.unaccepted {
			div.wpcf7-response-output {
				border: 1px solid #f1c7d2;
				background: #fae7ea;
				color: #e5547a;
			}
		}
	}

	div.wpcf7 {
	 .ajax-loader {
	 		background-color: transparent;
			background-image: url('../img/spinner.svg');
			background-size: cover !important;
			width: 40px;
			height: 40px;

			&:before {
				content: none;
			}
		}
	}

	span.wpcf7-not-valid-tip {
		font-size: 13px;
		line-height: 1.3em;
		padding-top: 5px;
	}

	span.wpcf7-list-item {
		margin: 0;
	}

	.wpcf7-form-control-wrap {
		position: static;
	}
}

/* Mesages & errors */

.flash-message {
	@include rem(font-size, 16px);
	padding: 0;
	background: #ebf5bc;
	border: 1px solid #96d775;
	color: #46b450;
	@include rem(padding, 15px 20px);
	@include rem(margin, 0 0 30px 0);

	&.error {
		border: 1px solid #f1c7d2;
		background: #fae7ea;
		color: #e5547a;
	}
}

/************************************************************************************
TABS
*************************************************************************************/

.tabs {
	position: relative;

	.tab-nav {
		border-bottom: 4px solid #B1B1B1;

		ul {
			@include rem(margin, 0 -10px);
			padding: 0;
			display: flex;
		}

		li {
			list-style-type: none;
			@include rem(padding, 0 10px);
			background: none;
			margin: 0 0 -4px 0;

			a {
				@include rem(font-size, 20px);
				line-height: 1.3em;
				display: block;
				text-decoration: none;
				outline: 0;
				@include rem(padding, 20px 0);
				color: #727272;
				border-bottom: 4px solid transparent;

				&:hover {
					color: #000;
				}

				&.selected {
					border-bottom: 4px solid #000;
					color: #000;
				}
			}
		}
	}

	.tab-content {
		.tab {
			width: 100%;
			padding: 0;
			background: none;
			display: none;
			@include rem(padding-top, 80px);

			&.selected {
				position: static !important;
				display: block;
			}
		}
	}
}

/************************************************************************************
SIMPLE LIGHTBOX
*************************************************************************************/

html.slbActive {
	overflow: hidden;
}

.slbArrow {
	font-size: 15px;

	&.prev {
		background: #fff;
		width: 40px;
		height: 40px;
		margin-top: -20px;
		opacity: 1;
		@include transition(all, .5);

		&:before {
			border: none;
			border-right: 2px solid #000;
			border-bottom: 2px solid #000;
			@include rotate(135deg);
			width: 15px;
			height: 15px;
			margin-top: -7.5px;
			margin-left: -6px;
			@include transition(all, .5);
		}

		&:hover {
			opacity: 1;
			background: #000;

			&:before {
				border-right: 2px solid #fff;
				border-bottom: 2px solid #fff;
			}
		}
	}

	&.next {
		background: #fff;
		width: 40px;
		height: 40px;
		margin-top: -20px;
		opacity: 1;
		@include transition(all, .5);

		&:before {
			border: none;
			border-right: 2px solid #000;
			border-bottom: 2px solid #000;
			@include rotate(-45deg);
			width: 15px;
			height: 15px;
			margin-top: -7.5px;
			@include transition(all, .5);
		}

		&:hover {
			opacity: 1;
			background: #000;

			&:before {
				border-right: 2px solid #fff;
				border-bottom: 2px solid #fff;
			}
		}
	}
}

.slbCloseBtn {
	font-size: 30px;
	line-height: 40px;
	font-weight: 400;
	font-family: peachy-keen-jf,sans-serif;
	color: #fff;
	width: 40px;
	height: 40px;
	background: #fff;
	color: #000;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	right: 0;
	top: 0;
	position: fixed;
	font-size: 0;
	@include transition(all, .5);

	&:after {
		content: '';
		width: 24px;
		height: 2px;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -12px;
		margin-top: -1px;
		display: block;
		background: #000;
		@include rotate(45deg);
		@include transition(all, .5);
	}

	&:before {
		content: '';
		width: 2px;
		height: 24px;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-top: -12px;
		margin-left: -1px;
		display: block;
		background: #000;
		@include rotate(45deg);
		@include transition(all, .5);
	}

	&:hover {
		opacity: 1;
		background: #000;
		color: #fff;

		&:after {
			background: #fff;
			@include rotate(-45deg);
		}

		&:before {
			background: #fff;
			@include rotate(-45deg);
		}
	}
}

.slbImage {
	@include rem(padding, 15px 0);
}

.slbImageWrap {
	&:after {
		content: none;
	}
}

.slbCaption {
	@include rem(font-size, 16px);
	position: static;
	overflow: visible;
	white-space: normal;
	padding-top: 0;
}

/************************************************************************************
MODAL
*************************************************************************************/

.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0,0,0,.5);
  overflow: auto;
  align-items: center;
  justify-content: center;

  &.active {
    display: flex;
  }

  .modal-content {
  	@include rem(padding, 50px);

  	&.alt {
  		background: #f9fbfc;
  	}
  }

  .modal-box {
	  @include rem(font-size, 18px);
	  @include rem(max-width, 400px);
	  background-color: #fff;
	  line-height: 1.5em;
	  margin: auto;
	  position: relative;

	  .modal-close {
	    position: absolute;
	    right: 0;
	    top: 0;
	    text-decoration: none;
	    @include rem(width, 40px);
	    @include rem(height, 40px);
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    background: #000;
	    color: #fff;
		@include transition(all, .3);

	    &:after {
	    	content: '×';
	    	@include rem(font-size, 30px);
	    	position: absolute;
	    	left: 0;
	    	top: 0;
	    	width: 100%;
	    	height: 100%;
	    	display: flex;
	    	justify-content: center;
	    	align-items: center;
	    	color: #fff;
			@include transition(all, .3);
			cursor: pointer;
	    }

	    .icon {
	    	@include rem(width, 18px);
	    	@include rem(height, 18px);
	    	transform: rotate(0deg);
	    	@include transition(all, .3);
	    }

	    &:hover {
	    	background: #fff;

	    	&:after {
	    		color: #000;
	    	}

	    	.icon {
	    		transform: rotate(90deg);
	    	}
	    }
	  }

	  &.modal-order {
			width: 100%;
			@include rem(max-width, 580px);
			background: #e5e5e5;
		}
	}

	.modal-box-wrap {
	  width: 100%;
	  //max-width: 500px;
	  @include rem(padding, 50px 15px 15px);
	  margin: auto;
	  overflow: hidden;
	  position: relative;
	  animation-name: animateTop;
	  animation-duration: .4s;
	}
}

@keyframes animateTop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

/************************************************************************************
GLIDE
*************************************************************************************/

.glide__slides {
	margin: 0;
}

.glide__slide {
	padding: 0 !important;
	margin: 0;

	&:before {
		content: none !important;
	}
}

.glide-nav {
	margin: 0;
	position: relative;

	.glide__arrow--left {
		position: absolute;
		left: -100px;
		top: 50%;
		@include rem(margin-top, -30px);
		@include rem(width, 60px);
		@include rem(height, 60px);
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;		
		background: #fff;
		color: $red;
		border-radius: 5px;
		@include transition(all, .3);

		.icon {
			@include rem(width, 25px);
			@include rem(height, 25px);
		}

		&:hover {
			background: $red;
			color: #fff;
		}
	}

	.glide__arrow--right {
		position: absolute;
		right: -100px;
		top: 50%;
		@include rem(margin-top, -30px);
		@include rem(width, 60px);
		@include rem(height, 60px);
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #fff;
		color: $red;
		border-radius: 5px;
		@include transition(all, .3);

		.icon {
			@include rem(width, 25px);
			@include rem(height, 25px);
		}

		&:hover {
			background: $red;
			color: #fff;
		}
	}
}

.glide__bullets {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;

	.glide__bullet {
		background: #a9e5da;
		width: 9px;
		height: 9px;
		border-radius: 50%;
		padding: 0;
		margin: 0 7px;
		border: none;
		cursor: pointer;
		@include transition(all, .5);

		&.glide__bullet--active, &:hover {
			background: #ea4650;
		}
	}
}

/* Photo slider */

.photo-slider {
	max-width: 1340px;
	margin-left: auto;
	margin-right: auto;
	position: relative;

	.glide-nav {
		position: static;
	}

	.glide__bullets {
		position: absolute;
		left: 0;
		@include rem(bottom, -50px);
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.item {
		display: flex;
		align-items: center;
		@include transition(all, .3);

		.img {
			display: inline-block;
			width: 100%;
			border-radius: 10px;
			overflow: hidden;
			box-shadow: 0 0 20px 0 rgba(0,0,0,.3);
			position: relative;
			overflow: hidden;

			img {
				width: 100%;
				@include scale(1);
				@include transition(all, .3);
			}

			&:hover {
				img	{
					@include scale(1.05);
				}
			}
		}
	}

	.item-inner {
		width: 100%;
		display: flex;
		justify-content: center;
	}
}

/************************************************************************************
CLEARFIX
*************************************************************************************/

/* http://www.yuiblog.com/blog/2010/09/27/clearfix-reloaded-overflowhidden-demystified */

.clearfix:before,
.clearfix:after {
	content: '\0020';
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}

.clearfix:after {
	clear: both;
}

.clearfix {
	zoom: 1;
}

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
}

.embed-container iframe, .embed-container object, .embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}