/**
 * Colors
 */
$black: #000;
$white: #fff;
$red: #981417;

/**
/* Font weight variables
 */
$font-light: 100;
$font-normal: 300;
$font-medium: 500;
$font-semibold: 700;
$font-bold: 900;