/************************************************************************************
LAYOUT
*************************************************************************************/

.news {
	.section-head {
		text-align: left;
	}

	.item {
		@include rem(margin-bottom, 30px);
		display: flex;

		h3 {
			@include rem(font-size, 25px);
			line-height: 1.5em;
			@include rem(margin-bottom, 20px);
		}

		.text {
			flex-grow: 1;
		}

		.more {
			font-size: 13px;
			font-weight: 600;
			display: flex;
			align-items: center;
			color: $red;

			.icon {
				@include rem(width, 20px);
				@include rem(height, 20px);
				@include rem(margin-right, 10px);
			}
		}
	}

	.item-inner {
		background: #DBD7D7;
		@include rem(padding, 20px);
		display: flex;
		flex-direction: column;
	}

	.foot {
		@include rem(font-size, 21px);
		text-align: center;
		display: flex;
		justify-content: center;
		@include rem(padding-top, 20px);
	}

	&.news-latest {
		h2 {
			@include rem(margin-bottom, 30px);
		}

		.item-inner {
			background: none;
			padding: 0;
			display: block;
			border-bottom: 2px solid #DEDEDE;
			@include rem(padding-bottom, 20px);
			width: 100%;
		}

		.item {
			@include rem(margin-bottom, 20px);
			
			.news-info {
				justify-content: flex-start;
			}

			&:last-child {
				.item-inner {
					border: none;
				}
			}
		}

		.tags {
			.tag {
				background: #DEDEDE;
			}
		}
	}
}

.news-info {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	@include rem(margin-bottom, 10px);

	.date {
		padding: 4px 0;
		margin: 0 20px 0 0;
		font-size: 13px;
	}
}

.news-detail {
	.news-info {
		justify-content: flex-start;

		.tags {
			.tag {
				background: #fff;
				color: $red;
			}
		}
	}
}

.tags {
	ul {
		margin: 0 -10px;

		li {
			margin: 0;
			padding: 0 10px;
		}
	}

	.tag {
		font-size: 13px;
		display: inline-block;
		background: #fff;
		padding: 2px 10px;
	}
}

.places {
	.section-head {
		text-align: left;
	}

	.item {
		font-size: 16px;
		line-height: 1.8em;
		@include rem(margin-bottom, 40px);
	}

	.item-inner {
		position: relative;
		text-decoration: none;
		display: block;
		color: #fff;

		p {
			display: none;
		}

		&:hover {
			.text {
				background: rgba(182,32,37,.9);
				justify-content: space-between;
				height: 100%;
			}

			p {
				display: block;
			}
		}
	}

	.text {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		max-height: auto;
		background: rgba(0,0,0,.5);
		@include rem(padding, 20px);
		display: flex;
		flex-direction: column;
		@include transition(all, .3);		

		h3 {
			color: #fff;
		}
	}

	.img {
		img {
			width: 100%;
		}
	}
}

.privates {
	.section-head {
		text-align: left;
	}

	.item {
		@include rem(margin-bottom, 20px);

		.img {
			@include rem(width, 60px);
			@include rem(height, 60px);
			@include rem(flex, 0 0 60px);
			@include rem(margin-right, 20px);
			background: #DBD7D7;
			display: flex;
			justify-content: center;
			align-items: center;
			@include transition(all, .3);

			.icon {
				@include rem(width, 42px);
				@include rem(height, 42px);
			}
		}

		h3 {
			margin-bottom: 0;
			color: $red;

			a {
				color: $red;
			}

			.icon {
				display: inline-block;
				margin-left: 10px;
				position: relative;
				@include rem(top, 8px);
			}
		}
	}

	.item-inner {
		display: flex;
		align-items: center;
		color: $red;

		&:hover {
			.img {
				background: $red;
				color: #fff;
			}
		}
	}
}

.clubs {
	.main {
		@include rem(padding, 40px 45px);
		background: #fff;
	}

	.item {
		max-width: 20;
		flex: 0 0 20%;
		@include rem(margin, 10px 0);

		&.col-auto {
			padding-left: 10px;
			padding-right: 10px;
		}

		.img {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 120px;
			@include rem(padding, 0 10px);

			.frame {
				max-width: 150px;
			}
		}
	}
}

.sports {
	.item {
		@include rem(margin-bottom, 30px);

		.text {
			@include rem(font-size, 30px);
			line-height: 1.3em;
			font-weight: 500;
			color: $red;
			display: flex;
			align-items: center;
		}

		.img {
			@include rem(width, 45px);
			@include rem(height, 45px);
			@include rem(flex, 0 0 45px);

			.icon {
				@include rem(width, 45px);
				@include rem(height, 45px);
				color: $red;
			}
		}
	}

	.item-inner {
		display: flex;
	}
}

.boxes {
	.item {
		@include rem(margin-bottom, 20px);

		.img {
			@include rem(width, 80px);
			@include rem(height, 80px);
			@include rem(flex, 0 0 80px);

			.icon {
				@include rem(width, 80px);
				@include rem(height, 80px);
				color: $red;
			}
		}

		.num {
			@include rem(font-size, 55px);
			line-height: 1.2em;
			color: $red;
			margin: 0;
		}
	}

	.item-inner {
		@include rem(padding, 20px);
		box-shadow: 0 0 20px 0 rgba(0,0,0,.2);
		display: flex;
		background: #fff;
	}
}

.box {
	@include rem(padding, 35px);
	box-shadow: 0 0 20px 0 rgba(0,0,0,.2);
	background: #fff;

	h3 {
		@include rem(margin-bottom, 20px);
	}
}

.side-box {
	box-shadow: 0 0 20px 0 rgba(0,0,0,.2);
	background: #fff;
	@include rem(margin-bottom, -70px);
	position: relative;
	z-index: 20;

	.box-text {
		@include rem(padding, 35px);
	}

	a {
		color: #000;
	}
}

.side-box-news {
	margin-bottom: -10000px;
}

.contact-item {
	@include rem(padding-top, 10px);
	@include rem(padding-bottom, 10px);

	.name {
		@include rem(font-size, 21px);
		font-weight: 600;
		margin: 0;
	}

	.job {
		@include rem(font-size, 18px);
		margin: 0;
	}

	ul {
		padding-top: 10px;
		margin: 0;

		li {
			padding: 0;

			.label {
				display: inline-block;
				margin-right: 10px;
			}

			a {
				color: $red;
			}
		}
	}
}

.opening {
	h3 {
		@include rem(margin-bottom, 10px);
	}

	ul {
		@include rem(margin-bottom, 30px);

		&:last-child {
			margin-bottom: 0;
		}

		li {
			padding: 0;
			display: flex;
			justify-content: space-between;

			&.link-map {
				a {
					display: flex;
					align-items: center;

					.icon {
						@include rem(width, 20px);
						@include rem(height, 20px);
						margin-right: 5px;
					}
				}
			}
		}

		a {
			color: $red;
		}
	}
}

.video {
	position: relative;
	display: block;
	overflow: hidden;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.5);
	}

	.play {
		position: absolute;
		left: 50%;
		top: 50%;
		@include rem(width, 80px);
		@include rem(height, 80px);
		@include rem(margin-left, -40px);
		@include rem(margin-top, -40px);
		background: #fff;
		z-index: 10;

		&:after {
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 16px 0 16px 30px;
			border-color: transparent transparent transparent $red;
			@include translate(-50%,-50%);
		}
	}

	img {
		@include scale(1);
		@include transition(all, .3);
	}

	&:hover {
		img	{
			@include scale(1.05);
		}
	}
}

.box-shadow {
	box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1), 0 16px 24px -16px rgba(0,0,0,0.1);
}

.file-list {
	.item-list {
		@include rem(margin-bottom, -30px);
	}

	.item {
		@include rem(margin-bottom, 30px);

		.img {
			@include rem(margin-bottom, 20px);

			.icon {
				@include rem(width, 95px);
				@include rem(height, 95px);
				color: #DBD7D7;
			}
		}

		.dl {
			display: flex;
			align-items: center;
			color: $red;

			.icon {
				@include rem(width, 15px);
				@include rem(height, 15px);
				@include rem(margin-right, 10px);
				color: $red;
			}
		}
	}
}

.gallery {
	.item {
		@include rem(margin-bottom, 20px);

		.img {
			position: relative;
			overflow: hidden;
			display: block;

			img {
				@include scale(1);
				@include transition(all, .3);
			}

			&:hover {
				img	{
					@include scale(1.05);
				}
			}
		}
	}
}

.excerpt {
	@include rem(font-size, 25px);
	line-height: 1.5em;

	p {
		margin: 0;
	}
}

.pt-150 {
	@include rem(padding-top, 150px);
}

.mb--150 {
	@include rem(margin-bottom, -150px);
}

form .contact-addon {
	display: none;
}

.modal {
	.modal-box.modal-notification {
		width: 100%;
		max-width: 36.25rem;
		background: #fff;
		border-radius: 10px;
		.modal-content {
			padding: 0;
			.img {
				border-radius: 10px 10px 0 0;
				overflow: hidden;
				img {
					width: 100%;
					height: auto;
				}
			}
			.text {
				padding: 1.875rem;
				border-radius: 0 0 10px 10px;
			}
		}
	}
}

.fix-modal {
	position: fixed;
	z-index: 150;
	right: 0;
	top: 50%;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: #fff;
	transform: translate(100%, 0);
	transition: .3s all;
	.fix-trigger {
		display: flex;
		align-items: center;
		position: absolute;
		top: -130px;
		right: 0;
		transform: rotate(-90deg);
		transform-origin: 100% 100%;
		height: auto;
		width: auto;
		white-space: nowrap;
		padding: 10px 20px;
		background: #981417;
		cursor: pointer;
		transition: .3s all;
		&:hover {
			background-color: #000;
		}
		.txt {
			display: flex;
			align-items: center;
		}
	}
}